import React, { useCallback, useContext, useState } from 'react';

import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { getTripSales } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { splitValues, trimTrailingSlash, useTypedSelector } from 'helpers';
import { TripSale } from 'types';

import {
  PaginationComponent,
  usePagination,
} from '../../components/use-pagination';
import { FilterTripSale } from './filter-trip-sale';
import { TripSalesFilterParams } from './filters';

const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
  })
);

const TripSaleListItem = ({ tripSale }: { tripSale: TripSale }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TextCell
        label={tripSale.axis_code}
        align="center"
        style={{ fontFamily: 'Cairo' }}
      />

      <TextCell
        label={tripSale.ticket_serial}
        align="center"
        style={{ fontFamily: 'Cairo' }}
      />

      <TextCell label={tripSale?.vehicle_code} />

      <TextCell
        label={`${tripSale.driver_username || ''} ${
          tripSale.driver_name || ''
        }`}
      />

      <TextCell
        label={moment(tripSale.created_at).format('DD/MM/YYYY HH:mm')}
        align="center"
      />

      <TextCell label={String(tripSale.price || '')} align="center" />
    </TableRow>
  );
};

const TripSaleList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);
  const [isFiltering, setFiltering] = useState(false);

  const [filterParams, setFilterParams] = useState<Record<string, string>>({});

  const { direction } = useContext(LanguageContext);

  const tripSaleListIsLoading = useTypedSelector(
    state => state.tripSales.isLoading as boolean
  );

  const tripSaleListHasError = useTypedSelector(
    state => state.tripSales.hasError
  );

  const tripSalesTotalCount = useTypedSelector(
    state => state.tripSales.totalCount
  );

  const tripSaleList = useTypedSelector(state => {
    if (!state.tripSales.isLoading && Object.values(state.tripSales).length) {
      return Object.values(state.tripSales.collection);
    }

    return [];
  });

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getTripSales(page, rowSize, { q: { ticket_serial_cont: searchTerm } }),
    [searchTerm]
  );

  const fetchFilterResults = useCallback(
    (page: number, rowSize: number) => {
      const { driver, axis, vehicle, minDate, maxDate } = TripSalesFilterParams;

      return getTripSales(page, rowSize, {
        group_ids: filterParams.groups as unknown as string[],
        q: {
          [driver]: splitValues(filterParams[driver]),
          [axis]: splitValues(filterParams[axis]),
          [vehicle]: splitValues(filterParams[vehicle]),
          [minDate]: filterParams[minDate],
          [maxDate]: filterParams[maxDate],
        },
      });
    },
    [filterParams]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    if (isFiltering) return fetchFilterResults;

    return getTripSales;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFilterResults, isFiltering, isSearching]);

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    handleChangePage(null, 0);
  };

  const filterTripSales = (filters: Record<string, string>) => {
    if (Object.values(filters)?.length) {
      setFiltering(true);
      setSearching(false);
      setSearchTerm('');
    }

    resetPageState();

    setFilterParams(filters);
  };

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getTripSales(page, rowsPerPage, {
          q: { ticket_serial_cont: searchTerm },
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: tripSalesTotalCount,
        hasData: !!tripSaleList.length,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );

  return (
    <div dir={direction}>
      <CardTitle title={intl.formatMessage({ id: 'tripSales' })} />

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterTripSale
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
              showFilters: !!tripSaleList.length && !isSearching,
              filterTripSales,
              isSearching,
            }}
          />
        </Grid>

        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <DataTableContainer
        alignItems={tripSaleListIsLoading ? 'center' : 'flex-start'}
      >
        {tripSaleListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {tripSaleListIsLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {tripSaleList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Trip Sale Table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {intl.formatMessage({ id: 'axis' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'code' })}
                        </TableCell>

                        <TableCell>
                          {intl.formatMessage({ id: 'vehicle' })}
                        </TableCell>

                        <TableCell>
                          {intl.formatMessage({ id: 'driver' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'timeAndDate' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'amount' })}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {tripSaleList.map(tripSale => (
                        <TripSaleListItem key={tripSale.id} {...{ tripSale }} />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: 'auto auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={false}
                      baseURL={baseURL}
                      showNote={false}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>

      <Pagination />
    </div>
  );
};

export { TripSaleList };
