import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  editAdminCategoryPermissions,
  getAdminFullPermissions,
  successAlert,
} from 'actions';
import { PermissionItem } from 'app/admin-management/admins/permissions/permission-item';
import { basicButtonStyles, mainButtonStyles } from 'app/shared/styles';
import { useTypedSelector } from 'helpers';
import {
  getLoggedAdminId,
  hasGrantAccessForCategory,
  makeGetCategorySelectedPermissions,
} from 'selectors';
import { AdminPermissionList } from 'types';

const useStyles = makeStyles(() =>
  createStyles({
    tableHeadContainer: {
      backgroundColor: '#4B506D',
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
      maxHeight: 52,
      height: 52,
      padding: '0 10px',
    },
    tableHead: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 50px 0 25px',
    },
    tableHeadTitle: {
      color: '#FFF',
      fontWeight: 700,
      fontSize: 16,
    },
    button: {
      ...basicButtonStyles,
      backgroundColor: '#0FA66D',
      height: 35,
      color: '#FFF',
      '&:hover': {
        opacity: 0.9,
        backgroundColor: '#0FA66D',
      },
    },
    cancelButton: {
      ...mainButtonStyles,
      borderColor: '#fff',
      height: 35,
      padding: '0px 17px',
    },
    saveButton: {
      ...basicButtonStyles,
      backgroundColor: '#fff',
      height: 35,
      '&:hover': {
        opacity: 0.9,
        backgroundColor: '#fff',
      },
    },
    loading: {
      color: '#fff',
      marginRight: 50,
    },
  })
);

interface GroupedSystemPermissionsProps {
  systemName: string;
  subSystems: string[];
  permissions:
    | AdminPermissionList['permissionsByOrganization'][0]['subSystems']
    | AdminPermissionList['master_configuration'];
  permissionLevelType?: 'Group' | 'Organisation' | undefined;
  permissionLevelId?: string;
}

const GroupedSystemPermissions: React.FC<GroupedSystemPermissionsProps> = ({
  systemName,
  subSystems,
  permissions,
  permissionLevelType,
  permissionLevelId,
}) => {
  const match = useRouteMatch<{ id: string }>();
  const adminId = match.params.id;
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentAdminId = useTypedSelector(getLoggedAdminId) as string;

  const canGrantAccess = useTypedSelector(state =>
    hasGrantAccessForCategory(state, systemName)
  );

  const getSelectedPermissions = useMemo(
    makeGetCategorySelectedPermissions,
    []
  );

  const categoryAvailablePermissionsIds = useTypedSelector(state =>
    getSelectedPermissions(state, {
      systemName,
      adminId,
      systemType: permissionLevelType,
      systemId: permissionLevelId,
    })
  );

  const [newPermissionsList, setNewPermissionsList] = useState(
    categoryAvailablePermissionsIds
  );

  useEffect(() => {
    if (categoryAvailablePermissionsIds) {
      setNewPermissionsList(categoryAvailablePermissionsIds);
    }
  }, [categoryAvailablePermissionsIds]);

  const onChangePermission = (subSystem, newList) => {
    const newPermissions = {
      ...newPermissionsList,
      [subSystem]: newList,
    };

    setNewPermissionsList(newPermissions);
  };

  const handleSubmit = async () => {
    const newValues = _(newPermissionsList)
      .toArray()
      .flatMap(val => val)
      .value();

    setIsLoading(true);

    await Promise.resolve(
      dispatch(
        editAdminCategoryPermissions(
          adminId,
          newValues,
          permissionLevelType,
          permissionLevelId,
          systemName
        )
      )
    )
      .then(() => dispatch(getAdminFullPermissions(adminId)))
      .then(() =>
        dispatch(
          successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
        )
      )
      .finally(() => {
        setIsEditing(false);
        setIsLoading(false);
      });
  };

  const onCancel = () => {
    setIsEditing(!isEditing);
  };

  return (
    <TableContainer>
      <Table aria-label="Admin Info Table">
        <TableHead>
          <TableRow style={{ border: '0' }}>
            <TableCell colSpan={3} className={classes.tableHeadContainer}>
              <div className={classes.tableHead}>
                <div className={classes.tableHeadTitle}>
                  {intl.formatMessage({ id: 'component' })}
                </div>

                {canGrantAccess &&
                  currentAdminId !== adminId &&
                  !permissionLevelId && (
                    <>
                      {isLoading ? (
                        <CircularProgress
                          size={25}
                          className={classes.loading}
                        />
                      ) : (
                        <>
                          {isEditing ? (
                            <div>
                              <Button
                                variant="outlined"
                                color="default"
                                type="button"
                                className={classes.saveButton}
                                onClick={handleSubmit}
                              >
                                {intl.formatMessage({ id: 'save' })}
                              </Button>

                              <Button
                                variant="outlined"
                                color="default"
                                type="button"
                                className={classes.cancelButton}
                                onClick={onCancel}
                              >
                                {intl.formatMessage({ id: 'cancel' })}
                              </Button>
                            </div>
                          ) : (
                            <Button
                              variant="outlined"
                              color="default"
                              type="button"
                              className={classes.button}
                              onClick={() => setIsEditing(!isEditing)}
                            >
                              {intl.formatMessage({ id: 'editPermissions' })}
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {subSystems?.map((subSystem, index) => (
            <PermissionItem
              key={subSystem}
              subSystemName={subSystem}
              permissions={permissions}
              onChangePermission={onChangePermission}
              category={systemName}
              isEditingMasterConfig={isEditing}
              isLast={index === subSystems.length - 1}
              permissionLevelType={permissionLevelType}
              permissionLevelId={permissionLevelId}
              canEditSinglePermission={canGrantAccess}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { GroupedSystemPermissions };
