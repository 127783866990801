import React, { useEffect, useCallback, useContext } from 'react';

import { CircularProgress, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import {
  clearAlerts,
  getAdminFullPermissions,
  fetchLoggedAdminCategories,
  refreshToken,
  getGeneralPermissions,
  getAdmin,
} from 'actions';
import { NotAuthorized } from 'app/components/not-authorized';
import { PageNotFound } from 'app/components/page-not-found';
import { PrivateRoute } from 'app/components/shared/private-route';
import { MasterConfig } from 'app/configuration';
import { FinancialControl } from 'app/financial-control';
import { Login } from 'app/login';
import { Operations } from 'app/operations';
import { SuperDash } from 'app/super-dash';
import { LanguageContext } from 'context/intl.context';
import { history, useNetwork, useTypedSelector } from 'helpers';
import { theme as applicationTheme } from 'index';
import {
  getLoggedAdminData,
  canReadMasterConfig,
  canReadOperations,
  canReadFinancialControl,
  getIsSuperAdmin,
  canReadAdminManagement,
} from 'selectors';

import 'react-toastify/dist/ReactToastify.css';
import { AdminManagement } from './admin-management';
import { DIRECTIONS } from './i18n-locale/locales-constants';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const alert = useTypedSelector(state => state.alert);
  const isLoggedIn = useTypedSelector(state => state.authentication?.loggedIn);
  const { locale, direction } = useContext(LanguageContext);
  const intl = useIntl();
  const language = locale?.slice(0, 2);

  const theme = createTheme({
    ...applicationTheme,
    direction,
  });

  const isLoadingPermissions = useTypedSelector(
    state => state.permissions.isLoading
  );

  const canViewAdminManagement = useTypedSelector(canReadAdminManagement);
  const canViewOperations = useTypedSelector(canReadOperations);
  const canViewFinancialControl = useTypedSelector(canReadFinancialControl);
  const currentUser = useTypedSelector(getLoggedAdminData);
  const isSuperAdmin = useTypedSelector(getIsSuperAdmin);
  const currentUserId = currentUser?.id;

  const canViewMasterConfig = useTypedSelector(state =>
    canReadMasterConfig(state)
  );

  const isOnline = useNetwork();

  const clearAlert = useCallback(() => {
    dispatch(clearAlerts());
  }, [dispatch]);

  useEffect(() => {
    if (alert) {
      toast[alert.type as 'error' | 'success'](alert.message, {
        onClose: clearAlert,
      });
    }
  }, [alert, clearAlert]);

  useEffect(() => {
    history.listen(clearAlert);

    if (isSuperAdmin) {
      dispatch(getGeneralPermissions());
    }
  }, [clearAlert, isSuperAdmin, dispatch]);

  useEffect(() => {
    if (isLoggedIn && currentUserId) {
      dispatch(getAdmin(currentUserId));

      Promise.resolve(dispatch(getAdminFullPermissions(currentUserId))).then(
        () => dispatch(fetchLoggedAdminCategories())
      );
    } else {
      history.push('/login');
    }
  }, [currentUserId, dispatch, isLoggedIn]);

  // each 59 minutes the refresh token will be called, because it is the expiry time for the token
  useEffect(() => {
    let interval;
    if (isLoggedIn && isOnline) {
      interval = setInterval(() => {
        dispatch(refreshToken());
      }, 15 * 60 * 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [dispatch, isLoadingPermissions, isLoggedIn, isOnline]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ToastContainer
          position={direction === DIRECTIONS.RTL ? 'top-left' : 'top-right'}
          newestOnTop
          rtl={direction === DIRECTIONS.RTL}
        />

        <Helmet
          htmlAttributes={{
            lang: language,
          }}
          bodyAttributes={{
            dir: direction,
          }}
        >
          <title>{intl.formatMessage({ id: 'mwaslatMasrSuperDASH' })}</title>
        </Helmet>

        {isLoggedIn && isLoadingPermissions ? (
          <div
            style={{
              display: 'flex',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Switch>
            <Route path="/login">
              <Login />
            </Route>

            <Redirect from="/" exact to="/super-dash" />

            <PrivateRoute path="/super-dash">
              <SuperDash />
            </PrivateRoute>

            <PrivateRoute path="/configuration">
              {canViewMasterConfig ? <MasterConfig /> : <NotAuthorized />}
            </PrivateRoute>

            <PrivateRoute path="/adminManagement">
              {canViewAdminManagement ? <AdminManagement /> : <NotAuthorized />}
            </PrivateRoute>

            <PrivateRoute path="/operations">
              {canViewOperations ? <Operations /> : <NotAuthorized />}
            </PrivateRoute>

            <PrivateRoute path="/financialControl">
              {canViewFinancialControl ? (
                <FinancialControl />
              ) : (
                <NotAuthorized />
              )}
            </PrivateRoute>

            <PrivateRoute path="/">
              <PageNotFound />
            </PrivateRoute>
          </Switch>
        )}
      </BrowserRouter>
    </ThemeProvider>
  );
};

export { App };
