import { combineReducers } from 'redux';

import { admins } from 'reducers/admins.reducer';
import { alert } from 'reducers/alert.reducer';
import { authentication } from 'reducers/authentication.reducer';
import { devices } from 'reducers/devices.reducer';
import { drivers } from 'reducers/drivers.reducer';
import { groups } from 'reducers/groups.reducer';
import { organizations } from 'reducers/organizations.reducer';
import { permissions } from 'reducers/permissions.reducer';
import { routes } from 'reducers/routes.reducer';
import { stations } from 'reducers/stations.reducer';
import { stops } from 'reducers/stops.reducer';
import { tripSales } from 'reducers/trip-sales.reducer';
import { vehicleClasses } from 'reducers/vehicle-classes.reducer';
import { vehicleTypes } from 'reducers/vehicle-types.reducer';
import { vehicles } from 'reducers/vehicles.reducer';

const rootReducer = combineReducers({
  admins,
  alert,
  authentication,
  drivers,
  organizations,
  permissions,
  routes,
  groups,
  tripSales,
  stops,
  devices,
  vehicles,
  vehicleTypes,
  vehicleClasses,
  stations,
});

export default rootReducer;
