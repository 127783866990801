import React, { useContext } from 'react';

import { Box, Chip, FormHelperText, FormLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useIntl } from 'react-intl';

import {
  useStyles,
  MenuProps,
} from 'app/components/shared/groups-menu-multi-select/utils';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';

import closeIcon from 'assets/close-icon-grey.svg';

export type Values = {
  id: string;
  name: string;
};

type ParamTypes = {
  sendSelectedValues: (filterName: string, selectedFilters: string[]) => void;
  values: Values[];
  selected: string[];
  filterName?: string;
  label?: string;
  addBottomMargin?: boolean;
  error?: string;
  requiredAstrisk?: boolean;
  placeholderText?: string;
  nameParam?: string;
};

function MultiSelect({
  sendSelectedValues,
  selected,
  values,
  filterName = '',
  label,
  addBottomMargin,
  error,
  requiredAstrisk = false,
  placeholderText = 'all',
  nameParam = 'name',
}: ParamTypes) {
  const classes = useStyles();
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);
  const isRtl = locale === LOCALES.ARABIC;

  const displayLabel = label ? intl.formatMessage({ id: label }) : '';

  const handleChange = selectedGroup => {
    if (selected.includes(selectedGroup)) {
      const newStatusList: string[] | [] = selected?.filter(
        id => id !== selectedGroup
      );

      return sendSelectedValues(filterName, newStatusList);
    }

    return sendSelectedValues(filterName, [...selected, selectedGroup]);
  };

  const selectedLabels = () =>
    selected.map(
      selectedStatus =>
        values.find(status => status.id === selectedStatus)?.[nameParam]
    );

  const placeholder = placeholderText
    ? intl.formatMessage({ id: placeholderText })
    : '';

  return (
    <FormControl fullWidth style={{ marginBottom: addBottomMargin ? 22 : 0 }}>
      <FormLabel className={classes.formLabel}>
        {displayLabel}

        {requiredAstrisk && <strong style={{ color: 'red' }}>*</strong>}
      </FormLabel>

      <Select
        labelId={displayLabel}
        multiple
        value={selected}
        error={!!error}
        renderValue={(val: any) =>
          selectedLabels()?.length ? (
            <Box className={classes.box}>
              {selectedLabels()?.map((value, index) => (
                <Chip
                  key={value}
                  label={value}
                  style={{
                    margin: 4,
                  }}
                  onDelete={() => handleChange(val[index])}
                  onMouseDown={event => {
                    event.stopPropagation();
                  }}
                  deleteIcon={<img src={closeIcon} alt="close" />}
                  classes={{
                    deleteIcon: classes.deleteIcon,
                    root: classes.chipRoot,
                    label: classes.chipLabel,
                  }}
                />
              ))}
            </Box>
          ) : (
            <div className={classes.placeholder}>{placeholder}</div>
          )
        }
        MenuProps={MenuProps(isRtl)}
        variant="outlined"
        SelectDisplayProps={{
          style: {
            backgroundColor: 'white',
            padding: '5px 5px',
            minHeight: 32,
          },
        }}
        displayEmpty
      >
        <div>
          {values.map((value: Values) => (
            <MenuItem
              key={value.id}
              value={value.id}
              onClick={() => handleChange(value.id)}
              divider
            >
              <ListItemIcon>
                <Checkbox checked={selected.includes(value.id)} />
              </ListItemIcon>

              <ListItemText primary={value[nameParam]} />
            </MenuItem>
          ))}
        </div>
      </Select>

      {error && (
        <FormHelperText className={classes.errorText}>{error}</FormHelperText>
      )}
    </FormControl>
  );
}

export default MultiSelect;
