/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { isEqual } from 'lodash';
import map from 'lodash/map';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { permissionAccessLevel } from '_constants';
import {
  getAdminFullPermissions,
  grantGeneralPermission,
  successAlert,
} from 'actions';
import AutoCompleteInput from 'app/components/shared/Autocomplete';
import { GroupsCell } from 'app/components/shared/table';
import { basicButtonStyles, mainButtonStyles } from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import {
  generatePermissionLevelsValues,
  getCurrentAccessLevel,
  getTranslated,
  useTypedSelector,
} from 'helpers';
import {
  getGroupsWithReadPermission,
  getHasWritePermissionOnOrganization,
  getLoggedAdminId,
  makeGetCategoryAvailablePermissions,
} from 'selectors';
import { AdminPermissionList, Group, OperationsSubSystem } from 'types';

import EditIcon from './edit-icon.svg';

import fullAccessIcon from 'assets/full-access.svg';
import openEyeIcon from 'assets/open-eye.svg';
import restrictedIcon from 'assets/restricted.svg';

const icons = {
  NoAccess: restrictedIcon,
  FullAccess: fullAccessIcon,
  ReadOnly: openEyeIcon,
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#fff',
      padding: '0px 30px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    bottomRadius: {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderBottom: 0,
    },
    permissionsTitle: { margin: '20px 0', fontSize: 18 },
    permissionTypeTitle: { marginBottom: 12 },
    subSystem: {
      margin: '16px 0',
      display: 'flex',
      alignItems: 'center',
      borderBottomLeftRadius: '30px',
    },
    subSystemName: { padding: '0 42px', whiteSpace: 'nowrap' },
    rightContent: {
      padding: 0,
      paddingRight: 30,
      [theme.breakpoints.down('md')]: {
        paddingRight: 40,
      },
    },
    accessLevelContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    FullAccess: { fontWeight: 500, fontSize: 14 },
    ReadOnly: { fontWeight: 500, fontSize: 14 },
    NoAccess: { fontWeight: 500, fontSize: 14, color: '#CE0B24' },
    icon: {
      margin: '0 10px',
      paddingTop: 2,
    },
    actionButton: {
      marginRight: 10,
      width: 80,
      [theme.breakpoints.down('md')]: {
        marginBottom: 10,
      },
    },
    submitButton: {
      backgroundColor: '#0FA66D',
      '&:hover': {
        backgroundColor: '#0FA66D',
        opacity: 0.9,
      },
    },
    radioGroupCont: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    radioGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 430,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        minWidth: 40,
      },
    },
    radioLabel: {
      fontSize: 14,
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    saveButton: {
      ...mainButtonStyles,
      borderColor: '#fff',
      height: 30,
      width: 100,
      marginLeft: 11,
    },
    cancelButton: {
      ...basicButtonStyles,
      backgroundColor: '#fff',
      height: 30,
      width: 100,
      marginLeft: 11,
      '&:hover': {
        opacity: 0.9,
        backgroundColor: '#fff',
      },
    },
    loading: {
      color: '#4B506D',
    },
  })
);

const SystemsWithoutGroupsFunctionality = ['Devices', 'Groups', 'Admins'];

const formatPermission = (permissionsIds, isOrganization?: boolean) =>
  permissionsIds.map(id => ({
    id,
    type: isOrganization ? 'Organisation' : 'Group',
  }));

const PermissionItem: React.FC<{
  category: string;
  subSystemName: string;
  onChangePermission?: (
    subSystemName: string,
    permissions: string[] | undefined
  ) => void;
  permissions:
    | AdminPermissionList['permissionsByOrganization'][0]['subSystems']
    | AdminPermissionList['master_configuration'];
  canEditSinglePermission: boolean;
  permissionLevelType: 'Group' | 'Organisation' | undefined;
  isLast?: boolean;
  isEditingMasterConfig?: boolean;
  permissionLevelId?: string;
}> = ({
  category,
  subSystemName,
  onChangePermission,
  permissions,
  canEditSinglePermission,
  permissionLevelType,
  isLast,
  isEditingMasterConfig,
  permissionLevelId,
}) => {
  const match = useRouteMatch<{ id: string }>();
  const classes = useStyles();
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [readPermissionsGroups, setReadPermissionsGroups] = useState(
    [] as Group[]
  );

  const shouldHidePermissionType = useMemo(
    () => SystemsWithoutGroupsFunctionality.includes(subSystemName),
    [subSystemName]
  );

  const dispatch = useDispatch();

  const adminId = match.params.id;

  const currentAdminHasOrgWriteAccess = useTypedSelector(state =>
    getHasWritePermissionOnOrganization(
      state,
      subSystemName as OperationsSubSystem,
      permissionLevelId as string
    )
  );

  const groupsWithReadPermission = useTypedSelector(state =>
    getGroupsWithReadPermission(state, subSystemName as OperationsSubSystem)
  );

  useEffect(() => {
    if (!isEqual(readPermissionsGroups, groupsWithReadPermission)) {
      setReadPermissionsGroups(groupsWithReadPermission);
    }
  }, [groupsWithReadPermission, readPermissionsGroups]);

  const mappedGroups = useMemo(
    () =>
      groupsWithReadPermission
        .filter(group => group.organisation?.id === permissionLevelId)
        .map(group => ({
          id: group.id,
          name: getTranslated(group, locale),
        })),
    [groupsWithReadPermission, locale, permissionLevelId]
  );

  const currentAccessLevel = useMemo(
    () =>
      getCurrentAccessLevel(
        permissions?.[subSystemName]?.permissions ||
          permissions?.[subSystemName] ||
          []
      ),
    [subSystemName, permissions]
  );

  const [selectedValue, setSelectedValue] =
    useState<permissionAccessLevel>(currentAccessLevel);

  const initialGroups = useMemo(
    () =>
      map(permissions?.[subSystemName]?.groupsWithAccess, 'id')?.filter(id =>
        readPermissionsGroups.some(g => g.id === id)
      ) || [],
    [readPermissionsGroups, permissions, subSystemName]
  );

  const [selectedGroups, setSelectedGroups] = useState<string[]>(initialGroups);

  const [
    hasInitiallyOrganizationPermission,
    setIsInitiallyOrganizationPermission,
  ] = useState<boolean>(
    !permissions?.[subSystemName]?.groupsWithAccess?.length
  );

  const [isOrganizationAccess, setIsOrganizationAccess] = useState<boolean>(
    !permissions?.[subSystemName]?.groupsWithAccess?.length &&
      currentAdminHasOrgWriteAccess
  );

  const loggedAdminId = useTypedSelector(getLoggedAdminId) as string;

  const getCategoryAvailablePermissions = useMemo(
    makeGetCategoryAvailablePermissions,
    []
  );

  const categoryAvailablePermissions = useTypedSelector(state =>
    getCategoryAvailablePermissions(state, {
      systemName: category,
      adminId: loggedAdminId,
      systemType: permissionLevelType,
      systemId: permissionLevelId,
    })
  );

  const permissionList = useMemo(
    () =>
      generatePermissionLevelsValues(
        categoryAvailablePermissions?.[subSystemName]
      ),
    [categoryAvailablePermissions, subSystemName]
  );

  useEffect(() => {
    if (!isEditing && !isEditingMasterConfig) {
      setSelectedValue(currentAccessLevel);

      setIsInitiallyOrganizationPermission(
        !permissions?.[subSystemName]?.groupsWithAccess?.length &&
          selectedValue !== permissionAccessLevel.noAccess
      );

      setSelectedGroups(initialGroups);
    }
  }, [
    isEditingMasterConfig,
    currentAccessLevel,
    initialGroups,
    isEditing,
    permissions,
    selectedValue,
    subSystemName,
  ]);

  const onChange = event => {
    const newValue = event.target.value as permissionAccessLevel;
    setSelectedValue(newValue);

    if (!permissionLevelId) {
      onChangePermission?.(subSystemName, permissionList[newValue]);
    }
  };

  const clearPermissions = async (permissionsIds, isOrganization?: boolean) => {
    const groupsToBeDeleted = initialGroups;

    const permissionsToBeDeleted = isOrganization
      ? [permissionLevelId]
      : groupsToBeDeleted;

    if (permissionsToBeDeleted.length) {
      permissionsIds.forEach(async id => {
        if (id)
          await Promise.resolve(
            dispatch(
              grantGeneralPermission(
                adminId,
                id,
                [],
                formatPermission(permissionsToBeDeleted, isOrganization)
              )
            )
          );
      });
    }
  };

  const handleChangeAccess = async isOrganization => {
    const writePermissionId =
      categoryAvailablePermissions?.[subSystemName]?.write?.id;

    const readPermissionId =
      categoryAvailablePermissions?.[subSystemName]?.read?.id;

    const selectedEntities = isOrganization
      ? [permissionLevelId]
      : selectedGroups;

    const groupsToBeDeleted = initialGroups.filter(
      id => !selectedGroups.includes(id)
    );

    const entitiesToBeRemoved = isOrganization ? [] : groupsToBeDeleted;

    if (
      (isOrganization && initialGroups.length) ||
      (selectedGroups.length && hasInitiallyOrganizationPermission)
    ) {
      await clearPermissions(
        [readPermissionId, writePermissionId],
        !isOrganization
      );
    }
    if (
      selectedValue === permissionAccessLevel.fullAccess &&
      writePermissionId
    ) {
      await Promise.resolve(
        dispatch(
          grantGeneralPermission(
            adminId,
            readPermissionId,
            formatPermission(selectedEntities, isOrganization),
            formatPermission(entitiesToBeRemoved)
          )
        )
      );

      await Promise.resolve(
        dispatch(
          grantGeneralPermission(
            adminId,
            writePermissionId,
            formatPermission(selectedEntities, isOrganization),
            formatPermission(entitiesToBeRemoved)
          )
        )
      );
    }

    if (selectedValue === permissionAccessLevel.readOnly && readPermissionId) {
      clearPermissions([writePermissionId], isOrganization);

      await Promise.resolve(
        dispatch(
          grantGeneralPermission(
            adminId,
            readPermissionId,
            formatPermission(selectedEntities, isOrganization),
            formatPermission(entitiesToBeRemoved)
          )
        )
      );
    }
    if (selectedValue === permissionAccessLevel.noAccess) {
      await clearPermissions([readPermissionId, writePermissionId], true);
      await clearPermissions([readPermissionId, writePermissionId], false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await handleChangeAccess(isOrganizationAccess);

      await dispatch(getAdminFullPermissions(adminId));

      dispatch(
        successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
      );
    } finally {
      setIsEditing(false);
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    setIsEditing(false);

    setSelectedValue(currentAccessLevel);
    setSelectedGroups(initialGroups);

    setIsInitiallyOrganizationPermission(
      !permissions?.[subSystemName]?.groupsWithAccess?.length
    );

    setIsOrganizationAccess(
      !permissions?.[subSystemName]?.groupsWithAccess?.length &&
        currentAdminHasOrgWriteAccess
    );
  };

  const selectedAdminHasHigherPermission =
    (!currentAdminHasOrgWriteAccess &&
      hasInitiallyOrganizationPermission &&
      permissionList?.['Full Access']?.length === 2) ||
    (permissionList?.['Full Access']?.length === 1 &&
      currentAccessLevel === permissionAccessLevel.fullAccess);

  const disabled =
    !isOrganizationAccess &&
    !selectedGroups.length &&
    !!mappedGroups.length &&
    selectedValue !== permissionAccessLevel.noAccess;

  const canShowFullAccess =
    permissionList?.['Full Access'] && permissionList['Full Access'].length > 1;

  return (
    <TableRow className={`${classes.container} ${classes.bottomRadius}`}>
      <TableCell
        className={`${classes.subSystemName} ${isLast && classes.bottomRadius}`}
      >
        {subSystemName}
      </TableCell>

      {isLoading && (
        <TableCell colSpan={3} className={classes.rightContent} align="right">
          <CircularProgress size={25} className={classes.loading} />
        </TableCell>
      )}

      {!isLoading && (
        <>
          {selectedValue !== permissionAccessLevel.noAccess &&
          permissionLevelId &&
          !isEditing ? (
            <GroupsCell
              groups={
                permissions?.[subSystemName]?.groupsWithAccess || [
                  { id: 'all', en_name: 'All', ar_name: 'الكل' },
                ]
              }
            />
          ) : (
            <TableCell className={`${classes.subSystemName}`}>
              {isEditing &&
                selectedValue !== permissionAccessLevel.noAccess &&
                !shouldHidePermissionType && (
                  <div style={{ display: 'flex' }}>
                    {currentAdminHasOrgWriteAccess && (
                      <FormControlLabel
                        checked={isOrganizationAccess || !mappedGroups.length}
                        onChange={() => {
                          if (mappedGroups.length)
                            setIsOrganizationAccess(prev => !prev);
                        }}
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: 'organizationAccess' })}
                        classes={{ label: classes.radioLabel }}
                        disabled={!mappedGroups.length}
                      />
                    )}

                    {!isOrganizationAccess && !!mappedGroups.length && (
                      <AutoCompleteInput
                        sendSelectedValues={(_, selected) => {
                          setSelectedGroups(selected);
                        }}
                        selected={selectedGroups || []}
                        values={mappedGroups}
                        label=""
                        placeholderText="selectAGroup"
                        width={350}
                        showSelectAll
                      />
                    )}
                  </div>
                )}
            </TableCell>
          )}

          <TableCell
            className={`${classes.rightContent} ${
              isLast && classes.bottomRadius
            }`}
            align="right"
          >
            {isEditing || isEditingMasterConfig ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className={classes.radioGroupCont}>
                  <RadioGroup
                    row
                    className={classes.radioGroup}
                    value={selectedValue}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value={permissionAccessLevel.fullAccess}
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'fullAccess' })}
                      classes={{ label: classes.radioLabel }}
                      style={{
                        display: canShowFullAccess ? 'flex' : 'none',
                      }}
                    />

                    <FormControlLabel
                      value={permissionAccessLevel.readOnly}
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'readOnly' })}
                      classes={{ label: classes.radioLabel }}
                    />

                    <FormControlLabel
                      value={permissionAccessLevel.noAccess}
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'noAccess' })}
                      classes={{ label: classes.radioLabel }}
                    />
                  </RadioGroup>
                </div>

                {permissionLevelId && (
                  <div className={classes.buttonGroup}>
                    <Button
                      variant="outlined"
                      color="default"
                      type="button"
                      className={classes.saveButton}
                      onClick={handleSubmit}
                      disabled={disabled}
                      style={{
                        opacity: !disabled ? 1 : 0.7,
                        color: '#fff',
                      }}
                    >
                      {intl.formatMessage({ id: 'save' })}
                    </Button>

                    <Button
                      variant="outlined"
                      color="default"
                      type="button"
                      className={classes.cancelButton}
                      onClick={onCancel}
                    >
                      {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className={classes.accessLevelContainer}>
                  <img
                    src={icons[currentAccessLevel.replace(' ', '')]}
                    alt={selectedValue}
                    className={classes.icon}
                  />

                  <Typography
                    className={classes[currentAccessLevel.replace(' ', '')]}
                  >
                    {intl.formatMessage({
                      id: currentAccessLevel.replace(' ', ''),
                    })}
                  </Typography>
                </div>

                {permissionLevelId &&
                  canEditSinglePermission &&
                  !selectedAdminHasHigherPermission && (
                    <IconButton
                      onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        setIsEditing(true);
                      }}
                    >
                      <img src={EditIcon} alt="Edit" />
                    </IconButton>
                  )}
              </div>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export { PermissionItem };
