import React, { useMemo } from 'react';

import { Accordion, AccordionDetails, withStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  editAdminCategoryPermissions,
  getAdminFullPermissions,
  successAlert,
} from 'actions';
import { useTypedSelector } from 'helpers';
import {
  getLoggedAdminId,
  hasGrantAccessForCategory,
  makeGetAllPermissionsIds,
  makeGetSubSystemPermissions,
} from 'selectors';
import { AdminPermissionList } from 'types';

import { AccordionSummary } from './accordation-summary';
import { GroupedSystemPermissions } from './grouped-system-permissions';

const StyledAccordion = withStyles({
  root: {
    backgroundColor: '#F7F7F8',
    border: '0px',
    boxShadow: 'none',
    marginBottom: 12,

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

interface SubSystemPermissionsProps {
  systemName: string;
  categoryName: string;
  permissions:
    | AdminPermissionList['permissionsByOrganization'][0]['subSystems']
    | AdminPermissionList['master_configuration'];
  permissionLevelType?: 'Group' | 'Organisation' | undefined;
  permissionLevelId?: string;
}

const SubSystemPermissions: React.FC<SubSystemPermissionsProps> = ({
  systemName,
  categoryName,
  permissions,
  permissionLevelType,
  permissionLevelId,
}) => {
  const match = useRouteMatch<{ id: string }>();
  const adminId = match.params.id;
  const dispatch = useDispatch();
  const intl = useIntl();

  const currentAdminId = useTypedSelector(getLoggedAdminId) as string;

  const canGrantAccessWithPermissionLevel = useTypedSelector(state =>
    hasGrantAccessForCategory(state, systemName)
  );

  const level =
    permissionLevelType === 'Group'
      ? 'groups_permission_level'
      : 'organisations_permission_level';

  const getSubSystemPermissions = useMemo(makeGetSubSystemPermissions, []);

  const subSystems = useTypedSelector(state =>
    getSubSystemPermissions(state, { level, systemName, permissionLevelId })
  );

  const getAllPermissionsIds = useMemo(makeGetAllPermissionsIds, []);

  const allPermissionsIds = useTypedSelector(state =>
    getAllPermissionsIds(state, {
      systemName,
      adminId: currentAdminId,
      systemType: permissionLevelType,
      systemId: permissionLevelId,
    })
  );

  const onGrantFullControl = async () => {
    await Promise.resolve(
      dispatch(
        editAdminCategoryPermissions(
          adminId,
          allPermissionsIds,
          permissionLevelType,
          permissionLevelId,
          systemName
        )
      )
    )
      .then(() => dispatch(getAdminFullPermissions(adminId)))
      .then(() =>
        dispatch(
          successAlert(intl.formatMessage({ id: `successGrantFullControl` }))
        )
      );
  };

  const onDenyAllAccess = async () => {
    await Promise.resolve(
      dispatch(
        editAdminCategoryPermissions(
          adminId,
          [],
          permissionLevelType,
          permissionLevelId,
          systemName
        )
      )
    )
      .then(() => dispatch(getAdminFullPermissions(adminId)))
      .then(() =>
        dispatch(
          successAlert(intl.formatMessage({ id: `successDenyAllAccess` }))
        )
      );
  };

  return (
    <StyledAccordion key={systemName}>
      <AccordionSummary
        ariaControls={systemName}
        entityName={categoryName}
        onDenyAllAccess={onDenyAllAccess}
        onGrantFullControl={onGrantFullControl}
        showActions={canGrantAccessWithPermissionLevel}
        variant="secondary"
      />

      <AccordionDetails>
        <GroupedSystemPermissions
          {...{
            systemName,
            subSystems,
            permissions,
            permissionLevelType,
            permissionLevelId,
          }}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export { SubSystemPermissions };
