import React, { useContext, useMemo } from 'react';

import { Accordion, AccordionDetails, withStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  editAdminCategoryPermissions,
  editAllPermissionsForOrganizationOrGroup,
  getAdminFullPermissions,
  successAlert,
} from 'actions';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, useTypedSelector } from 'helpers';
import {
  getLoggedAdminId,
  hasGrantAccessForCategory,
  makeGetAllPermissionsIds,
  makeGetCategoriesForPermissionLevel,
} from 'selectors';
import { AdminPermissionList } from 'types';

import { AccordionSummary } from './accordation-summary';
import { GroupedSystemPermissions } from './grouped-system-permissions';
import { SubSystemPermissions } from './sub-system-permissions';

const StyledAccordion = withStyles({
  root: {
    backgroundColor: '#F7F7F8',
    border: '0px',
    boxShadow: 'none',
    marginBottom: 12,

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

interface AccordionSummaryProps {
  title: string;
  ariaControls: string;
  icon: string;
  entityName?: string;
}

interface SystemPermissionsProps {
  systemName?: string;
  subSystems?: string[];
  permissions:
    | AdminPermissionList['permissionsByOrganization'][0]['subSystems']
    | AdminPermissionList['master_configuration'];
  accordionSummaryProps: AccordionSummaryProps;
  permissionLevelType?: 'Group' | 'Organisation' | undefined;
  permissionLevelId?: string;
}

const SystemPermissions: React.FC<SystemPermissionsProps> = ({
  systemName,
  subSystems,
  permissions,
  accordionSummaryProps,
  permissionLevelType,
  permissionLevelId,
}) => {
  const match = useRouteMatch<{ id: string }>();
  const adminId = match.params.id;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const currentAdminId = useTypedSelector(getLoggedAdminId) as string;

  const canGrantAccess = useTypedSelector(state =>
    hasGrantAccessForCategory(state, systemName)
  );

  const getCategories = useMemo(makeGetCategoriesForPermissionLevel, []);

  const categories = useTypedSelector(state =>
    getCategories(state, { type: permissionLevelType, permissionLevelId })
  );

  const getAllPermissionsIds = useMemo(makeGetAllPermissionsIds, []);

  const allPermissionsIds = useTypedSelector(state =>
    getAllPermissionsIds(state, {
      systemName,
      adminId: currentAdminId,
      systemType: permissionLevelType,
      systemId: permissionLevelId,
    })
  );

  const getGrantAction = () => {
    if (systemName) {
      return dispatch(
        editAdminCategoryPermissions(
          adminId,
          allPermissionsIds,
          permissionLevelType,
          permissionLevelId,
          systemName
        )
      );
    }

    if (permissionLevelType && permissionLevelId) {
      return dispatch(
        editAllPermissionsForOrganizationOrGroup(
          adminId,
          permissionLevelType,
          permissionLevelId,
          'allow'
        )
      );
    }

    return null;
  };

  const getDenyAction = () => {
    if (systemName) {
      return dispatch(
        editAdminCategoryPermissions(
          adminId,
          [],
          permissionLevelType,
          permissionLevelId,
          systemName
        )
      );
    }

    if (permissionLevelType && permissionLevelId) {
      return dispatch(
        editAllPermissionsForOrganizationOrGroup(
          adminId,
          permissionLevelType,
          permissionLevelId,
          'deny'
        )
      );
    }

    return null;
  };

  const onGrantFullControl = async () => {
    await Promise.resolve(getGrantAction())
      .then(() => dispatch(getAdminFullPermissions(adminId)))
      .then(() =>
        dispatch(
          successAlert(intl.formatMessage({ id: `successGrantFullControl` }))
        )
      );
  };

  const onDenyAllAccess = async () => {
    await Promise.resolve(getDenyAction())
      .then(() => dispatch(getAdminFullPermissions(adminId)))
      .then(() =>
        dispatch(
          successAlert(intl.formatMessage({ id: `successDenyAllAccess` }))
        )
      );
  };

  const canShowAccordion =
    systemName === 'master_configuration' || !!categories?.length;

  return canShowAccordion ? (
    <StyledAccordion>
      <AccordionSummary
        {...accordionSummaryProps}
        onDenyAllAccess={onDenyAllAccess}
        onGrantFullControl={onGrantFullControl}
        showActions={canGrantAccess}
      />

      <AccordionDetails style={{ display: 'block' }}>
        {systemName === 'master_configuration' ? (
          <GroupedSystemPermissions
            {...{
              systemName,
              subSystems: subSystems as string[],
              permissions,
              permissionLevelType,
              permissionLevelId,
            }}
          />
        ) : (
          categories?.map(category => (
            <SubSystemPermissions
              key={category.identifier}
              {...{
                systemName: category.identifier,
                categoryName: getTranslated(category, locale),
                permissions,
                permissionLevelType,
                permissionLevelId,
              }}
            />
          ))
        )}
      </AccordionDetails>
    </StyledAccordion>
  ) : null;
};

export { SystemPermissions };
